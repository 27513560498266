import {
  HttpClient,
  HttpHeaders,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
/* tslint:disable */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ApiConfiguration } from '../api-configuration';
import { BaseService } from '../base-service';
import { ApiResponse } from '../models/api-response';
import { Preventive_Notification_Setting } from '../models/preventive-_notification-_setting';
import { Preventive_Notification_Setting_Item } from '../models/preventive-_notification-_setting-_item';
import { TripAnalysisInfo } from '../models/trip-analysis-info';
import { Vehicle_Alert_Info } from '../models/vehicle-_alert-_info';
import { Vehicle_Debug_Log_File_Info } from '../models/vehicle-_debug-_log-_file-_info';
import { Vehicle_Maint_Info } from '../models/vehicle-_maint-_info';
import { Vehicle_Maint_Log_File_Info } from '../models/vehicle-_maint-_log-_file-_info';
import { Vehicle_Notif_Info } from '../models/vehicle-_notif-_info';
import { Vehicle_Snapshot } from '../models/vehicle-_snapshot';
import { VehicleMCCInfo } from '../models/vhicle-_MCC-info';
import { VehicleDto } from './../models/vehicle/vehicle.dto';
import { AuthenticationService } from './authentication.service';

/**
 * Access to fleet information
 */
@Injectable()
class VehicleService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient,
    private authService: AuthenticationService
  ) {
    super(config, http);
  }

  getVehicles() {
    return this.http.get<VehicleDto[]>(`${this.rootUrl}/iotp/user/vehicles`);
  }

  /**
   * Returns a single fleet
   * @param vehicleId Pass in vehicle id
   * @return successful operation
   */
  getVehicleByIdResponse(
    vehicleId: number
  ): Observable<HttpResponse<Vehicle_Alert_Info>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/vehicle/${vehicleId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: Vehicle_Alert_Info = null;
        _body = _resp.body as Vehicle_Alert_Info;
        return _resp.clone({ body: _body }) as HttpResponse<Vehicle_Alert_Info>;
      })
    );
  }

  /**
   * Return MCC data array
   * @param vehicleId
   */
  getVehicleMCCByIdResponse(
    vehicleId: number
  ): Observable<HttpResponse<VehicleMCCInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/vehicle/MCC/${vehicleId}`,
      __body,
      { headers: __headers, params: __params, responseType: 'json' }
    );
    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ApiResponse = null;
        _body = _resp.body as ApiResponse;
        return _resp.clone({ body: _body }) as HttpResponse<VehicleMCCInfo>;
      })
    );
  }

  /**
   * Returns a single fleet
   * @param vehicleId Pass in vehicle id
   * @return successful operation
   */
  getVehicleSnapshotRequestResponse(
    vehicleId: number,
    userName: string
  ): Observable<HttpResponse<ApiResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (userName != null) __params = __params.set('username', userName);

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/vehicle/requestSnapshot/${vehicleId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ApiResponse = null;
        _body = _resp.body as ApiResponse;
        return _resp.clone({ body: _body }) as HttpResponse<ApiResponse>;
      })
    );
  }

  /**
   * Returns a single fleet
   * @param vehicleId Pass in vehicle id, dataId Pass in data id
   * @return successful operation
   */
  getVehicleSnapshotByDataResponse(
    vehicleId: number,
    dataId: number
  ): Observable<HttpResponse<Vehicle_Snapshot>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/vehicle/${vehicleId}/status/${dataId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: Vehicle_Snapshot = null;
        _body = _resp.body as Vehicle_Snapshot;
        return _resp.clone({ body: _body }) as HttpResponse<Vehicle_Snapshot>;
      })
    );
  }

  /**
   * Returns a single fleet
   * @param vehicleId Pass in vehicle id
   * @return successful operation
   */
  getVehicleById(vehicleId: number): Observable<Vehicle_Alert_Info> {
    return this.getVehicleByIdResponse(vehicleId).pipe(map((_r) => _r.body));
  }

  /**
   * Returns MCC data array
   * @param vehicleId
   */
  getVehicleMCCById(vehicleId: number): Observable<VehicleMCCInfo> {
    return this.getVehicleMCCByIdResponse(vehicleId).pipe(map((_r) => _r.body));
  }

  /**
   * Returns a single fleet
   * @param vehicleId Pass in vehicle id
   * @return successful operation
   */
  getVehicleSnapshotRequest(
    vehicleId: number,
    userName: string
  ): Observable<ApiResponse> {
    return this.getVehicleSnapshotRequestResponse(vehicleId, userName).pipe(
      map((_r) => _r.body)
    );
  }

  getVehicleSnapshotByDataId(
    vehicleId: number,
    dataId: number
  ): Observable<Vehicle_Snapshot> {
    return this.getVehicleSnapshotByDataResponse(vehicleId, dataId).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * Returns a vehicle Maint log information
   * @param params The `VehicleService.GetVehicleMaintLogInfoParams` containing the following parameters:
   *
   * - `vehicleId`: Pass in vehicle id
   *
   * - `username`: Pass in vehicle id
   *
   * - `year`: Pass in year
   *
   * - `result_count`: Pass in how many result desire
   *
   * - `month`: Pass in month
   *
   * @return successful operation
   */
  getVehicleMaintLogInfoResponse(
    params: VehicleService.GetVehicleMaintLogInfoParams
  ): Observable<HttpResponse<Vehicle_Maint_Info>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.username != null)
      __params = __params.set('username', params.username.toString());
    if (params.year != null)
      __params = __params.set('year', params.year.toString());
    if (params.resultCount != null)
      __params = __params.set('result_count', params.resultCount.toString());
    if (params.month != null)
      __params = __params.set('month', params.month.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/vehicle/maintLogInfo/${params.vehicleId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: Vehicle_Maint_Info = null;
        _body = _resp.body as Vehicle_Maint_Info;
        return _resp.clone({ body: _body }) as HttpResponse<Vehicle_Maint_Info>;
      })
    );
  }

  /**
   * Returns a vehicle Maint log information
   * @param params The `VehicleService.GetVehicleMaintLogInfoParams` containing the following parameters:
   *
   * - `vehicleId`: Pass in vehicle id
   *
   * - `username`: Pass in vehicle id
   *
   * - `year`: Pass in year
   *
   * - `result_count`: Pass in how many result desire
   *
   * - `month`: Pass in month
   *
   * @return successful operation
   */
  getVehicleMaintLogInfo(
    params: VehicleService.GetVehicleMaintLogInfoParams
  ): Observable<Vehicle_Maint_Info> {
    return this.getVehicleMaintLogInfoResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * Returns a vehicle routine log information
   * @param params The `VehicleService.GetVehicleRoutineLogFileParams` containing the following parameters:
   *
   * - `year`: Pass in year
   *
   * - `vehicleId`: Pass in vehicle id
   *
   * - `username`: Pass in vehicle id
   *
   * - `result_count`: Pass in how many result desire
   *
   * - `month`: Pass in month
   *
   * - `zip_pkg`: Pass in vehicle id
   *
   * @return successful operation
   */
  getVehicleRoutineLogFileResponse(
    params: VehicleService.GetVehicleRoutineLogFileParams
  ): Observable<HttpResponse<Vehicle_Maint_Log_File_Info>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.year != null)
      __params = __params.set('year', params.year.toString());

    if (params.username != null)
      __params = __params.set('username', params.username.toString());
    if (params.resultCount != null)
      __params = __params.set('result_count', params.resultCount.toString());
    if (params.month != null)
      __params = __params.set('month', params.month.toString());
    if (params.zipPkg != null)
      __params = __params.set('zip_pkg', params.zipPkg.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/vehicle/routineLogFileInfo/${params.vehicleId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: Vehicle_Maint_Log_File_Info = null;
        _body = _resp.body as Vehicle_Maint_Log_File_Info;
        return _resp.clone({ body: _body }) as HttpResponse<
          Vehicle_Maint_Log_File_Info
        >;
      })
    );
  }

  /**
   * Returns a vehicle routine log information
   * @param params The `VehicleService.GetVehicleRoutineLogFileParams` containing the following parameters:
   *
   * - `year`: Pass in year
   *
   * - `vehicleId`: Pass in vehicle id
   *
   * - `username`: Pass in vehicle id
   *
   * - `result_count`: Pass in how many result desire
   *
   * - `month`: Pass in month
   *
   * - `zip_pkg`: Pass in vehicle id
   *
   * @return successful operation
   */
  getVehicleRoutineLogFile(
    params: VehicleService.GetVehicleRoutineLogFileParams
  ): Observable<Vehicle_Maint_Log_File_Info> {
    return this.getVehicleRoutineLogFileResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * Returns a vehicle connection and debug log status
   * @param params The `VehicleService.GetVehicleDebugLogFileParams` containing the following parameters:
   *
   * - `vehicleId`: Pass in vehicle id
   *
   * - `username`: username
   *
   * - `result_count`: Pass in how many result desire
   *
   * - `zip_pkg`: Pass in zip_pkg
   *
   * @return successful operation
   */
  getVehicleDebugLogFileResponse(
    params: VehicleService.GetVehicleDebugLogFileParams
  ): Observable<HttpResponse<Vehicle_Debug_Log_File_Info>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.username != null)
      __params = __params.set('username', params.username.toString());
    if (params.resultCount != null)
      __params = __params.set('result_count', params.resultCount.toString());
    if (params.zipPkg != null)
      __params = __params.set('zip_pkg', params.zipPkg.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/vehicle/debugLogFileInfo/${params.vehicleId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: Vehicle_Debug_Log_File_Info = null;
        _body = _resp.body as Vehicle_Debug_Log_File_Info;
        return _resp.clone({ body: _body }) as HttpResponse<
          Vehicle_Debug_Log_File_Info
        >;
      })
    );
  }

  /**
   * Returns a vehicle connection and debug log status
   * @param params The `VehicleService.GetVehicleDebugLogFileParams` containing the following parameters:
   *
   * - `vehicleId`: Pass in vehicle id
   *
   * - `username`: username
   *
   * - `result_count`: Pass in how many result desire
   *
   * - `zip_pkg`: Pass in zip_pkg
   *
   * @return successful operation
   */
  getVehicleDebugLogFile(
    params: VehicleService.GetVehicleDebugLogFileParams
  ): Observable<Vehicle_Debug_Log_File_Info> {
    return this.getVehicleDebugLogFileResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * Returns vehicle maintenance setting information (Req. 6-0)
   * @param params The `VehicleService.GetVehicleMaintSettingParams` containing the following parameters:
   *
   * - `vehicleId`: Pass in vehicle id
   *
   * - `username`: Pass in user name
   *
   * @return successful operation
   */
  getVehicleMaintSettingResponse(
    params: VehicleService.GetVehicleMaintSettingParams
  ): Observable<HttpResponse<Preventive_Notification_Setting>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.vehicleId != null)
      __params = __params.set('vehicleId', params.vehicleId.toString());
    if (params.username != null)
      __params = __params.set('username', params.username.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/vehicle/preventiveNotifItemSetting`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: Preventive_Notification_Setting = null;
        _body = _resp.body as Preventive_Notification_Setting;
        return _resp.clone({ body: _body }) as HttpResponse<
          Preventive_Notification_Setting
        >;
      })
    );
  }

  /**
   * Returns vehicle maintenance setting information (Req. 6-0)
   * @param params The `VehicleService.GetVehicleMaintSettingParams` containing the following parameters:
   *
   * - `vehicleId`: Pass in vehicle id
   *
   * - `username`: Pass in user name
   *
   * @return successful operation
   */
  getVehicleTripReportResponse(
    params: VehicleService.GetVehicleReportInfoParams
  ): Observable<HttpResponse<TripAnalysisInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.vehicleId != null)
      __params = __params.set('vehicleId', params.vehicleId.toString());
    if (params.username != null)
      __params = __params.set('username', params.username.toString());
    if (params.fleetId != null)
      __params = __params.set('fleetId', params.fleetId.toString());
    if (params.begin_date != null)
      __params = __params.set('begin_date', params.begin_date.toString());
    if (params.end_date != null)
      __params = __params.set('end_date', params.end_date.toString());

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/vehicle/trip/${params.fleetId}/${params.vehicleId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: TripAnalysisInfo = null;
        _body = _resp.body as TripAnalysisInfo;
        return _resp.clone({ body: _body }) as HttpResponse<TripAnalysisInfo>;
      })
    );
  }

  /**
   * Returns vehicle trip report
   * @param params The `VehicleService.GetVehicleReportInfoParams` containing the following parameters:
   *
   * - `vehicleId`: Pass in vehicle id
   *
   * - `username`: Pass in user name
   *
   * - 'fleetId': Pass in fleet id
   *
   * - 'begin_date': begin date (optional)
   *
   * - 'end_date': end date (optional)
   *
   *
   *
   * @return successful operation
   */
  getVehicleTripReport(
    params: VehicleService.GetVehicleReportInfoParams
  ): Observable<TripAnalysisInfo> {
    return this.getVehicleTripReportResponse(params).pipe(map((_r) => _r.body));
  }

  /**
   * Returns vehicle maintenance setting information (Req. 6-0)
   * @param params The `VehicleService.GetVehicleMaintSettingParams` containing the following parameters:
   *
   * - `vehicleId`: Pass in vehicle id
   *
   * - `username`: Pass in user name
   *
   * @return successful operation
   */
  getVehicleMaintSetting(
    params: VehicleService.GetVehicleMaintSettingParams
  ): Observable<Preventive_Notification_Setting> {
    return this.getVehicleMaintSettingResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * Returns a single vehicle preventive maintenance setting information
   * @param body Created user object
   */
  updateVehicleMaintSettingResponse(
    vehicleId: number,
    username: string,
    body: Preventive_Notification_Setting_Item
  ): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;

    if (vehicleId != null)
      __params = __params.set('vehicleId', vehicleId.toString());
    if (username != null)
      __params = __params.set('username', username.toString());

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/vehicle/preventiveNotifItemSetting`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({ body: _body }) as HttpResponse<void>;
      })
    );
  }

  /**
   * Returns a single vehicle preventive maintenance setting information
   * @param body Created user object
   */
  updateVehicleMaintSetting(
    vehicleId: number,
    username: string,
    body: Preventive_Notification_Setting_Item
  ): Observable<void> {
    return this.updateVehicleMaintSettingResponse(
      vehicleId,
      username,
      body
    ).pipe(map((_r) => _r.body));
  }

  /**
   * Returns vehicle preventive maintenance information (Req. 5-0)
   * @param params The `VehicleService.GetPreventiveMaintNotifInfoParams` containing the following parameters:
   *
   * - `vehicleId`: Pass in vehicle id
   *
   * - `username`: Pass in username
   *
   * - `preventiveItemID`: Pass in notification_item_id ID
   *
   * @return successful operation
   */
  getPreventiveMaintNotifInfoResponse(
    params: VehicleService.GetPreventiveMaintNotifInfoParams
  ): Observable<HttpResponse<Vehicle_Notif_Info>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.vehicleId != null)
      __params = __params.set('vehicleId', params.vehicleId.toString());
    if (params.username != null)
      __params = __params.set('username', params.username.toString());

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/vehicle/preventiveNotifItem/${params.preventiveItemID}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: Vehicle_Notif_Info = null;
        _body = _resp.body as Vehicle_Notif_Info;
        return _resp.clone({ body: _body }) as HttpResponse<Vehicle_Notif_Info>;
      })
    );
  }

  /**
   * Returns vehicle preventive maintenance information (Req. 5-0)
   * @param params The `VehicleService.GetPreventiveMaintNotifInfoParams` containing the following parameters:
   *
   * - `vehicleId`: Pass in vehicle id
   *
   * - `username`: Pass in username
   *
   * - `preventiveItemID`: Pass in notification_item_id ID
   *
   * @return successful operation
   */
  getPreventiveMaintNotifInfo(
    params: VehicleService.GetPreventiveMaintNotifInfoParams
  ): Observable<Vehicle_Notif_Info> {
    return this.getPreventiveMaintNotifInfoResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * Updates a single vehicle preventive maintenance setting information (Req. 5-0)
   * @param params The `VehicleService.UpdatePreventiveNotifItemParams` containing the following parameters:
   *
   * - `vehicleId`: Pass in vehicle Id item id
   *
   * - `username`: Pass in username
   *
   * - `reset_status`: reset status
   *
   * - `preventiveItemID`: Pass in preventive item id
   */
  updatePreventiveNotifItemResponse(
    params: VehicleService.UpdatePreventiveNotifItemParams
  ): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.vehicleId != null)
      __params = __params.set('vehicleId', params.vehicleId.toString());
    if (params.username != null)
      __params = __params.set('username', params.username.toString());
    if (params.resetStatus != null)
      __params = __params.set('reset_status', params.resetStatus.toString());

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/vehicle/preventiveNotifItem/${params.preventiveItemID}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({ body: _body }) as HttpResponse<void>;
      })
    );
  }

  /**
   * Updates a single vehicle preventive maintenance setting information (Req. 5-0)
   * @param params The `VehicleService.UpdatePreventiveNotifItemParams` containing the following parameters:
   *
   * - `vehicleId`: Pass in vehicle Id item id
   *
   * - `username`: Pass in username
   *
   * - `reset_status`: reset status
   *
   * - `preventiveItemID`: Pass in preventive item id
   */
  updatePreventiveNotifItem(
    params: VehicleService.UpdatePreventiveNotifItemParams
  ): Observable<void> {
    return this.updatePreventiveNotifItemResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * User acknowledge of alert notification.
   * @param params The `VehicleService.UpdateVehicleAlertSnapshotParams` containing the following parameters:
   *
   * - `username`: Pass in username
   *
   * - `alertId`: Pass in alert id
   *
   * - `ack_status`: Pass in ack status
   */
  updateVehicleAlertSnapshotResponse(
    params: VehicleService.UpdateVehicleAlertSnapshotParams
  ): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.username != null)
      __params = __params.set('username', params.username.toString());

    if (params.ackStatus != null)
      __params = __params.set('ack_status', params.ackStatus.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/vehicle/alert/${params.alertId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({ body: _body }) as HttpResponse<void>;
      })
    );
  }

  /**
   * User acknowledge of alert notification.
   * @param params The `VehicleService.UpdateVehicleAlertSnapshotParams` containing the following parameters:
   *
   * - `username`: Pass in username
   *
   * - `alertId`: Pass in alert id
   *
   * - `ack_status`: Pass in ack status
   */
  updateVehicleAlertSnapshot(
    params: VehicleService.UpdateVehicleAlertSnapshotParams
  ): Observable<void> {
    return this.updateVehicleAlertSnapshotResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /** Overrides the newParams function to include the user's API token */
  public newParams() {
    return super
      .newParams()
      .set('cats_api_key', this.authService.getSessionToken());
  }
}

module VehicleService {
  /**
   * Parameters for getVehicleMaintLogInfo
   */
  export interface GetVehicleMaintLogInfoParams {
    /**
     * Pass in vehicle id
     */
    vehicleId: number;

    /**
     * Pass in vehicle id
     */
    username: string;

    /**
     * Pass in year
     */
    year?: number;

    /**
     * Pass in how many result desire
     */
    resultCount?: number;

    /**
     * Pass in month
     */
    month?: number;
  }

  /**
   * Parameters for getVehicleRoutineLogFile
   */
  export interface GetVehicleRoutineLogFileParams {
    /**
     * Pass in year
     */
    year: number;

    /**
     * Pass in vehicle id
     */
    vehicleId: number;

    /**
     * Pass in vehicle id
     */
    username: string;

    /**
     * Pass in how many result desire
     */
    resultCount: number;

    /**
     * Pass in month
     */
    month: number;

    /**
     * Pass in vehicle id
     */
    zipPkg?: 'yes' | 'no';
  }

  /**
   * Parameters for getVehicleDebugLogFile
   */
  export interface GetVehicleDebugLogFileParams {
    /**
     * Pass in vehicle id
     */
    vehicleId: number;

    /**
     * username
     */
    username: string;

    /**
     * Pass in how many result desire
     */
    resultCount: number;

    /**
     * Pass in zip_pkg
     */
    zipPkg?: 'yes' | 'no';
  }

  /**
   * Parameters for getVehicleMaintSetting
   */
  export interface GetVehicleMaintSettingParams {
    /**
     * Pass in vehicle id
     */
    vehicleId: number;

    /**
     * Pass in user name
     */
    username: string;
  }

  /**
   * Parameters for getVehicleReportInfo
   */
  export interface GetVehicleReportInfoParams {
    /**
     * Pass in vehicle id
     */
    vehicleId: number;

    /**
     * Pass in user name
     */
    username: string;

    /**
     * Pass in fleetId
     */
    fleetId: number;

    /**
     * Pass in begin date
     */
    begin_date?: string;

    /**
     * Pass in end date
     */
    end_date?: string;
  }

  /**
   * Parameters for updateVehicleAlertSnapshot
   */
  export interface GetVehicleRequestSnapshotParams {
    /**
     * Pass in username
     */
    username: string;
  }

  /**
   * Parameters for getPreventiveMaintNotifInfo
   */
  export interface GetPreventiveMaintNotifInfoParams {
    /**
     * Pass in vehicle id
     */
    vehicleId: number;

    /**
     * Pass in username
     */
    username: string;

    /**
     * Pass in notification_item_id ID
     */
    preventiveItemID: number;
  }

  /**
   * Parameters for updatePreventiveNotifItem
   */
  export interface UpdatePreventiveNotifItemParams {
    /**
     * Pass in vehicle Id item id
     */
    vehicleId: number;

    /**
     * Pass in username
     */
    username: string;

    /**
     * reset status
     */
    resetStatus: 'reset' | 'no_reset';

    /**
     * Pass in preventive item id
     */
    preventiveItemID: number;
  }

  /**
   * Parameters for updateVehicleAlertSnapshot
   */
  export interface UpdateVehicleAlertSnapshotParams {
    /**
     * Pass in username
     */
    username: string;

    /**
     * Pass in alert id
     */
    alertId: number;

    /**
     * Pass in ack status
     */
    ackStatus: 'acknowledged' | 'not_acknowledged';
  }

  /** Parameters for updating the vehicle maint. setting */
  export interface UpdateVehicleMaintSettingParams
    extends GetVehicleMaintSettingParams {
    // Request body
    body: Array<Preventive_Notification_Setting_Item>;
  }
}

export { VehicleService };
