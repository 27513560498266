import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ApiConfiguration } from '../api-configuration';
import { BaseService } from '../base-service';
import { AuthMessage } from '../models/auth/auth-message';
import { DecodedTokenDto } from './../models/auth/decoded-token.dto';
import { LoginResponseDto } from './../models/auth/login-response.dto';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService extends BaseService implements OnDestroy {
  // Stores the address of the auth API
  private authAddress = this.rootUrl + '/user/login';
  // The string representing the local-storage ID for username
  private usernameID: string = 'username';
  // The string representing the local-storage ID for the login time-stamp
  private loginTimestampID: string = 'login-timestamp';
  // The string representing the local-storage ID for the session token
  private sessionTokenID: string = 'session-token';
  // The string representing the local-storage ID for the company name
  private companyNameID: string = 'company-name';
  // List of local-storage ID's
  private localStorageID: string[] = [
    this.usernameID,
    this.loginTimestampID,
    this.sessionTokenID,
    this.companyNameID
  ];
  // Subject used to distribute any authentication error messages throughout the system
  private authListener: BehaviorSubject<AuthMessage>;

  private jwtHelper: JwtHelperService;

  constructor(
    config: ApiConfiguration,
    http: HttpClient,
    private readonly cookieService: CookieService,
    public router: Router
  ) {
    super(config, http);

    this.jwtHelper = new JwtHelperService();

    // Initializes the listener with an empty message
    this.authListener = new BehaviorSubject<AuthMessage>({
      error: false,
      message: 'OK'
    });
  }

  ngOnDestroy() {}

  /** Authenticates the user by attempting to make a RESTful call to the CATS API
   *  to verify if the provided credentials are correct */
  public authenticate(
    username: string,
    password: string
  ): Observable<LoginResponseDto> {
    return this.http
      .post<LoginResponseDto>(`${this.rootUrl}/iotp/auth/user/login`, {
        userName: username,
        password: password
      })
      .pipe(
        tap((response) => {
          this.saveUserCredentials(response.accessToken);
        })
      );
  }

  saveUserCredentials(accessToken: string) {
    this.storeSessionToken(accessToken);

    this.http
      .get<any>(`${this.rootUrl}/iotp/user/account`)
      .subscribe((response) => {
        this.storeTimestamp(response.lastLoginDate);
        this.storeUsername(response.userName);
      });

    // this.authService.storeCompanyName(response['company_name']);
  }

  /** @return the username of the user that is currently logged in */
  public getUsername() {
    return this.cookieService.get(this.usernameID);
    // return window.sessionStorage.getItem(this.usernameID);
  }

  /** @return the timestamp from when the user has logged on */
  public getLoginTimestamp() {
    return this.cookieService.get(this.loginTimestampID);
    // return window.sessionStorage.getItem(this.loginTimestampID);
  }

  /** @return the user's session token */
  public getSessionToken() {
    return this.cookieService.get(this.sessionTokenID);
    // return window.sessionStorage.getItem(this.sessionTokenID);
  }

  /** @return the user's company name */
  public getCompanyName() {
    return this.cookieService.get(this.companyNameID);
    // return window.sessionStorage.getItem(this.companyNameID);
  }

  /** Stores the user's username to localstorage
   * 		@param username {string} - The username that would be stored in the browser's local-storage */
  public storeUsername(username: string): void {
    this.cookieService.set(this.usernameID, username);
    // window.sessionStorage.setItem(this.usernameID, username);
  }

  /** Stores the user's logged-in timestamp to local-storage
   * 		@param timestamp {Date} - The timestamp since the user last logged in as a data object */
  public storeTimestamp(timestamp: Date): void {
    this.cookieService.set(this.loginTimestampID, timestamp.toString());
    // window.sessionStorage.setItem(this.loginTimestampID, timestamp.toString());
  }

  /** Stores the user's session token */
  public storeSessionToken(token: string): void {
    this.cookieService.set(this.sessionTokenID, token, 0);
    // window.sessionStorage.setItem(this.sessionTokenID, token);
  }

  /** Stores the user's company name */
  public storeCompanyName(name: string): void {
    this.cookieService.set(this.companyNameID, name);
    // window.sessionStorage.setItem(this.companyNameID, name);
  }

  /** Removes the user's username credential from the browser's local-storage */
  public removeUsername(): void {
    this.cookieService.delete(this.usernameID);
    // window.sessionStorage.removeItem(this.usernameID);
  }

  /** Removes the user's logged-in time-stamp from the browser's local-storage */
  public removeTimestamp(): void {
    this.cookieService.delete(this.loginTimestampID);
    // window.sessionStorage.removeItem(this.loginTimestampID);
  }

  /** Removes the user's session token */
  public removeSessionToken(): void {
    this.cookieService.delete(this.sessionTokenID);
    // window.sessionStorage.removeItem(this.sessionTokenID);
  }

  /** Removes the user's company name */
  public removeCompanyName(): void {
    this.cookieService.delete(this.companyNameID);
    // window.sessionStorage.removeItem(this.companyNameID);
  }

  /** Clears out all localstorage assets */
  public clearLocalStorage(): void {
    // this.localStorageID.forEach((id) => {
    //   window.sessionStorage.removeItem(id);
    // });
    this.cookieService.deleteAll();
  }

  /** Determines if the user is currently logged in */
  public isLoggedIn() {
    return !!this.getSessionToken();
  }

  /** Returns this service's auth listener as an observable. The auth listener is used to transmit any authentication
   *  messages throughout the system. */
  public getAuthListener() {
    return this.authListener.asObservable();
  }

  /** Tells this service to throw a token authentication error when called.
   * 		@param message {string} - The error message describing the situation */
  public throwAuthError(message: string): void {
    this.authListener.next({ error: true, message: message });
  }

  /** Handles a failed HTTP operation. The app is designed to
   *  continue (and log) the error message.
   *    @param {string} operation - The name of the operation that failed
   *    @param {T} result - [Optional] value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      return of(result as T);
    };
  }

  /** Decodes the JWT token and checks validates it's parameters
   * 	@return {DecodedTokenDto} the decoded token or null if any errors occur within the decoding process */
  getDecodedToken(): DecodedTokenDto {
    try {
      const decodedToken: DecodedTokenDto = this.jwtHelper.decodeToken(
        this.getSessionToken()
      );

      if (decodedToken) {
        // Validates the token's expiration date
        if (this.jwtHelper.isTokenExpired(this.getSessionToken())) {
          this.throwAuthError('Session expired');
          return null;
        }
        // TODO: Validate the token with the server

        return decodedToken;
      }
    } catch (error) {
      this.throwAuthError('Invalid session token');
    }
    return null;
  }
}
