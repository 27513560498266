/* tslint:disable */
import { formatDate } from '@angular/common';
import {
  HttpClient,
  HttpHeaders,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { ApiConfiguration } from '../api-configuration';
import { BaseService } from '../base-service';
import { HttpRequestType } from '../models/httprequesttype';
import { User } from '../models/user';
import * as UserAddress from '../models/user-address-model';
import { UserNotification } from '../models/user-notification';
import { UserProfileDto } from './../models/user/user-profile.dto';
import { AuthenticationService } from './authentication.service';

/**
 * Retrieve user related information from server.
 */
@Injectable()
class UserService extends BaseService {
  // Allows components to listen to any updates on the database
  private updateListener: Subject<void>;

  constructor(
    config: ApiConfiguration,
    http: HttpClient,
    private authService: AuthenticationService,
    @Inject(LOCALE_ID) private locale: string
  ) {
    super(config, http);
    this.updateListener = new Subject<void>();
  }

  getUserProfile() {
    return this.http.get<UserProfileDto>(`${this.rootUrl}/iotp/user/profile`);
  }

  updateUserProfile(userProfile: UserProfileDto) {
    return this.http.put(`${this.rootUrl}/iotp/user/profile`, {
      firstName: userProfile.firstName,
      lastName: userProfile.lastName,
      address: userProfile.address,
      phone: userProfile.phone
    });
  }

  /**
   * This can only be done by the logged in user.
   * @param body Created user object
   */
  createUserResponse(body: User): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/user`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'text'
    });

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({ body: _body }) as HttpResponse<void>;
      })
    );
  }

  /**
   * This can only be done by the logged in user.
   * @param body Created user object
   */
  createUser(body: User): Observable<void> {
    return this.createUserResponse(body).pipe(map((_r) => _r.body));
  }

  /**
   * @param body List of user object
   */
  createUsersWithArrayInputResponse(
    body: Array<User>
  ): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    __body['username'] = this.authService.getUsername();
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/user/createWithArray`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({ body: _body }) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param body List of user object
   */
  createUsersWithArrayInput(body: Array<User>): Observable<void> {
    return this.createUsersWithArrayInputResponse(body).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param body List of user object
   */
  createUsersWithListInputResponse(
    body: Array<User>
  ): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl +
        `/user/createWithList` +
        '?username=' +
        this.authService.getUsername(),
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({ body: _body }) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param body List of user object
   */
  createUsersWithListInput(body: Array<User>): Observable<void> {
    return this.createUsersWithListInputResponse(body).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `UserService.LoginUserParams` containing the following parameters:
   *
   * - `username`: The user name for login
   *
   * - `password`: The password for login in clear text
   *
   * @return successful operation
   */
  loginUserResponse(
    params: UserService.LoginUserParams
  ): Observable<HttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.username != null)
      __params = __params.set('username', params.username.toString());
    if (params.password != null)
      __params = __params.set('password', params.password.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/user/login`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: string = null;
        _body = _resp.body as string;
        return _resp.clone({ body: _body }) as HttpResponse<string>;
      })
    );
  }

  /**
   * @param params The `UserService.LoginUserParams` containing the following parameters:
   *
   * - `username`: The user name for login
   *
   * - `password`: The password for login in clear text
   *
   * @return successful operation
   */
  loginUser(params: UserService.LoginUserParams): Observable<string> {
    return this.loginUserResponse(params).pipe(map((_r) => _r.body));
  }
  logoutUserResponse(): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/user/logout`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({ body: _body }) as HttpResponse<void>;
      })
    );
  }
  logoutUser(): Observable<void> {
    return this.logoutUserResponse().pipe(map((_r) => _r.body));
  }

  /**
   * @param username The name that needs to be fetched. Use user1 for testing.
   * @return successful operation
   */
  getUserByNameResponse(username: string): Observable<HttpResponse<User>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/user/${username}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: User = null;
        _body = _resp.body as User;
        return _resp.clone({ body: _body }) as HttpResponse<User>;
      })
    );
  }

  /**
   * @param username The name that needs to be fetched. Use user1 for testing.
   * @return successful operation
   */
  getUserByName(username: string): Observable<User> {
    return this.getUserByNameResponse(username).pipe(
      map((_r) => _r.body),
      catchError(this.handleError('user-detail'))
    );
  }

  /**
   * This can only be done by the logged in user.
   * @param params The `UserService.UpdateUserParams` containing the following parameters:
   *
   * - `username`: name that need to be updated
   *
   * - `body`: Updated user object
   */
  updateUserResponse(
    params: UserService.UpdateUserParams
  ): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = params.body;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/user/${params.username}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({ body: _body }) as HttpResponse<void>;
      })
    );
  }

  /**
   * This can only be done by the logged in user.
   * @param params The `UserService.UpdateUserParams` containing the following parameters:
   *
   * - `username`: name that need to be updated
   *
   * - `body`: Updated user object
   */
  updateUser(params: UserService.UpdateUserParams): Observable<void> {
    return this.updateUserResponse(params).pipe(map((_r) => _r.body));
  }

  /**
   * This can only be done by the logged in user.
   * @param username The name that needs to be deleted
   */
  deleteUserResponse(username: string): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/user/${username}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({ body: _body }) as HttpResponse<void>;
      })
    );
  }

  /**
   * This can only be done by the logged in user.
   * @param username The name that needs to be deleted
   */
  deleteUser(username: string): Observable<void> {
    return this.deleteUserResponse(username).pipe(map((_r) => _r.body));
  }

  /**
   * @param username Retrieve all related alert and notifications for current logged in user.
   * @return successful operation
   */
  getUserNotificationResponse(
    username: string
  ): Observable<HttpResponse<UserNotification>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (username != null)
      __params = __params.set('username', username.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/user/notification`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: UserNotification = null;
        _body = _resp.body as UserNotification;
        return _resp.clone({ body: _body }) as HttpResponse<UserNotification>;
      })
    );
  }

  /**
   * @param username Retrieve all related alert and notifications for current logged in user.
   * @return successful operation
   */
  getUserNotification(username: string): Observable<UserNotification> {
    return this.getUserNotificationResponse(username).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `UserService.UpdateUserNotificationParams` containing the following parameters:
   *
   * - `username`: Retrieve all related alert and notifications for current logged in user.
   *
   * - `alert_id`: Retrieve all related alert and notifications for current logged in user.
   *
   * - `action`: Retrieve all related alert and notifications for current logged in user.
   */
  updateUserNotificationResponse(
    params: UserService.UpdateUserNotificationParams
  ): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.username != null)
      __params = __params.set('username', params.username.toString());
    if (params.alertId != null)
      __params = __params.set('alert_id', params.alertId.toString());
    if (params.action != null)
      __params = __params.set('action', params.action.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/user/notification`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({ body: _body }) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `UserService.UpdateUserNotificationParams` containing the following parameters:
   *
   * - `username`: Retrieve all related alert and notifications for current logged in user.
   *
   * - `alert_id`: Retrieve all related alert and notifications for current logged in user.
   *
   * - `action`: Retrieve all related alert and notifications for current logged in user.
   */
  updateUserNotification(
    params: UserService.UpdateUserNotificationParams
  ): Observable<void> {
    return this.updateUserNotificationResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /** pushes an update table request */
  public updateTable(): void {
    this.updateListener.next();
  }

  /** @return an observable of the update listener */
  public getUpdateListener() {
    return this.updateListener.asObservable();
  }

  /** Transforms a date object into a UTC converted SQL-style timestamp */
  public transformToUTC(date: Date) {
    return formatDate(date, 'yyyy-MM-dd HH:mm:ss', this.locale, 'UTC');
  }

  /** Prepares a user object to be sent to the database */
  public prepareUserObject(
    user: User,
    requestType: HttpRequestType,
    userAddress?: UserAddress.UserAddressModel
  ) {
    let changedUser: User = user;
    const phoneTransformExpression: any = /\D/g;

    // Transforms the user address object
    if (userAddress) {
      changedUser['address'] = UserAddress.addressToString(userAddress);
    }

    changedUser.phone = ('' + changedUser.phone).replace(
      phoneTransformExpression,
      ''
    );
    changedUser.user_contact = ('' + changedUser.user_contact).replace(
      phoneTransformExpression,
      ''
    );
    changedUser['name'] = changedUser.firstName + ' ' + changedUser.lastName;
    changedUser.userStatus = 'active';

    // Updates the user's timestamp object depending on which HTTP request is being used
    switch (requestType) {
      case HttpRequestType.POST: {
        changedUser.create_time = this.transformToUTC(new Date());
        break;
      }
      case HttpRequestType.PUT: {
        changedUser.modified_time = this.transformToUTC(new Date());
        break;
      }
      default: {
        break;
      }
    }
    return changedUser;
  }

  /** Handles a failed HTTP operation. The app is designed to
   *  continue (and log) the error message.
   *    @param {string} operation - The name of the operation that failed
   *    @param {T} result - [Optional] value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      return of(result as T);
    };
  }

  /** Overrides the newParams function to include the user's API token */
  public newParams() {
    return super
      .newParams()
      .set('cats_api_key', this.authService.getSessionToken());
  }
}

module UserService {
  /**
   * Parameters for loginUser
   */
  export interface LoginUserParams {
    /**
     * The user name for login
     */
    username: string;

    /**
     * The password for login in clear text
     */
    password: string;
  }

  /**
   * Parameters for updateUser
   */
  export interface UpdateUserParams {
    /**
     * name that need to be updated
     */
    username: string;

    /**
     * Updated user object
     */
    body: User;
  }

  /**
   * Parameters for updateUserNotification
   */
  export interface UpdateUserNotificationParams {
    /**
     * Retrieve all related alert and notifications for current logged in user.
     */
    username: string;

    /**
     * Retrieve all related alert and notifications for current logged in user.
     */
    alertId: string;

    /**
     * Retrieve all related alert and notifications for current logged in user.
     */
    action: string;
  }
}

export { UserService };
