import { AgmCoreModule } from '@agm/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule, Provider } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AngularFireModule } from 'angularfire2';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { ApiConfiguration } from 'app/api/api-configuration';
import { environment } from 'environments/environment';
import { ChartsModule } from 'ng2-charts';
import { NgxBootstrapSwitchModule } from 'ngx-bootstrap-switch';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { CookieService } from 'ngx-cookie-service';
import { ApiModule } from './api/api.module';
import { AppComponent } from './app.component';
// Import routing module
import { AppRoutingModule } from './app.routing';
// Import components
import {
  AppAsideComponent,
  AppBreadcrumbsComponent,
  AppFooterComponent,
  AppHeaderAccountComponent,
  AppHeaderComponent,
  AppSidebarComponent,
  AppSidebarMinimizerComponent,
  APP_SIDEBAR_NAV
} from './components';
// Import containers
import { FullLayoutComponent, SimpleLayoutComponent } from './containers';
// Import directives
import {
  AsideToggleDirective,
  NAV_DROPDOWN_DIRECTIVES,
  ReplaceDirective,
  SIDEBAR_TOGGLE_DIRECTIVES
} from './directives';
// Import pipes
import { AppPipeModule } from './pipes/pipes.module';
import './rxjs-extensions';
import { AuthInterceptor } from './services/auth.interceptor';
import { FormElementsModule } from './views/form-elements/form-elements.module';
import { SettingModule } from './views/setting/setting.module';

const APP_CONTAINERS = [FullLayoutComponent, SimpleLayoutComponent];

const APP_COMPONENTS = [
  AppAsideComponent,
  AppBreadcrumbsComponent,
  AppFooterComponent,
  AppHeaderComponent,
  AppSidebarComponent,
  AppSidebarMinimizerComponent,
  APP_SIDEBAR_NAV,
  AppHeaderAccountComponent
];

const APP_DIRECTIVES = [
  AsideToggleDirective,
  NAV_DROPDOWN_DIRECTIVES,
  ReplaceDirective,
  SIDEBAR_TOGGLE_DIRECTIVES
];

export function initApiConfiguration(config: ApiConfiguration): Function {
  return () => {
    if (environment && environment.apiRootIocCATS) {
      config.rootUrl = environment.apiRootIocCATS;
    }
  };
}

export const INIT_API_CONFIGURATION: Provider = {
  provide: APP_INITIALIZER,
  useFactory: initApiConfiguration,
  deps: [ApiConfiguration],
  multi: true
};

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TabsModule.forRoot(),
    NgxBootstrapSwitchModule.forRoot(),
    ChartsModule,
    NgxDatatableModule,
    AgmCoreModule.forRoot(environment.agm),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AppPipeModule,
    ApiModule,
    FormElementsModule,
    SettingModule
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    ...APP_COMPONENTS,
    ...APP_DIRECTIVES
  ],
  providers: [
    INIT_API_CONFIGURATION,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    CookieService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
