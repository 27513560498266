/* tslint:disable */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfiguration } from '../api-configuration';
import { BaseService } from '../base-service';
import { AlertDefinitionDto } from '../models/alert/alert-definition.dto';
import { AlertSubscriptionDto } from '../models/alert/alert-subscription.dt';
import { CreateAlertSubscriptionDto } from '../models/alert/create-alert-subscription.dto';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AlertService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient,
    private readonly authService: AuthenticationService
  ) {
    super(config, http);
  }

  getAlertDefinitions() {
    return this.http.get<AlertDefinitionDto[]>(
      `${this.rootUrl}/sign/gateways/messages/alerts/definitions`
    );
  }

  getAlertSubscriptions() {
    return this.http.get<AlertSubscriptionDto[]>(
      `${this.rootUrl}/sign/user/alerts/subscriptions`
    );
  }

  subscribeAlert(alertDefinitionId: number, fleetId: number) {
    const createAlertSubscription = new CreateAlertSubscriptionDto();
    createAlertSubscription.fleetId = fleetId;
    createAlertSubscription.alertDefinitionId = alertDefinitionId;
    createAlertSubscription.notifyByEmail = true;
    createAlertSubscription.notifyBySms = false;

    return this.http.post(
      `${this.rootUrl}/sign/user/alerts/subscriptions`,
      createAlertSubscription
    );
  }

  unsubscribeAlert(alertSubscriptionId: number) {
    return this.http.delete(
      `${this.rootUrl}/sign/user/alerts/subscriptions/${alertSubscriptionId}`
    );
  }
}
