import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  // Flag determining if the user is an administrator
  protected admin: boolean = false;
  // Flag determining if the user is part of the 'iocontrols-admin' group
  protected ioAdmin: boolean = false;
  // Flag determining if there exists a connection error while trying to connect to servers
  protected connectionError: boolean = false;

  constructor(
    private userService: UserService,
    private readonly authService: AuthenticationService
  ) {}

  /** Checks with the server to see if the user is an administrator
   * @param username {string} - The username of the user that is going to be checked */
  public verifyAdmin(username: string): void {
    this.connectionError = false;
    //new
    const token = this.authService.getDecodedToken();
    if (token && token.userInfo.userType === 'io_admin') {
      this.admin = true;
      this.ioAdmin = true;
    } else {
      this.admin = false;
      this.ioAdmin = false;
    }
    //old
    // this.userService.getUserByName(username).subscribe((user) => {
    //   try {
    //     this.admin =
    //       user['user_group_type'] === 'admin' || user['user_type'] === 'admin';
    //     this.ioAdmin = UserModel.isSuperAdmin(user);
    //     this.connectionError = false;
    //   } catch (err) {
    //     this.admin = false;
    //     this.connectionError = true;
    //   }
    // });
  }

  /** @return the flag indicating if this user is an admin */
  public isAdmin(): boolean {
    return !this.connectionError && (this.admin || this.ioAdmin);
  }

  /** @return true if the user is part of the 'iocontrols-admin' group */
  public isIOAdmin(): boolean {
    return !this.connectionError && this.ioAdmin;
  }

  /** @return the flag indicating if there is an error with the verification process  */
  public hasError(): boolean {
    return this.connectionError;
  }
}
