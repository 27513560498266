export const navigation = [
  {
    name: 'Fleet',
    url: '/fleet',
    icon: 'fa fa-bus'
  },
  {
    name: 'Alert',
    url: '/alert',
    icon: 'fa fa-bell'
  },
  {
    name: 'Schedule Status',
    url: '/schedule',
    icon: 'fa fa-file'
  },
  {
    name: 'Schedule Upload',
    url: '/scheduleUpload',
    icon: 'fa fa-file'
  },
  {
    name: 'Application Status',
    url: '/application',
    icon: 'fa fa-bug',
    admin: true
  },
  {
    name: 'Application Upload',
    url: '/applicationUpload',
    icon: 'fa fa-bug',
    admin: true
  },
  {
    name: 'Setting',
    url: '/setting',
    icon: 'fa fa-cog',
    admin: true
  }
];
