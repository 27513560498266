import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { AuthenticationService } from '../../api/services/authentication.service';

@Component({
  selector: 'app-header-account',
  templateUrl: './app-header-account.component.html',
  styleUrls: ['./app-header-account.component.css']
})
export class AppHeaderAccountComponent implements OnInit, OnDestroy {
  // Stores the username acquired from the web token
  private username: string;
  // Stores the time-stamp when the user last logged in
  private loginTime: Date;
  // Flag used to determine if the user is logged in
  private loggedFlag: boolean;
  // Defines the interval in which the user login time-stamp automatically upates
  private updateInterval: any;
  // Used to update the login timer every second
  private sub: Subscription;

  constructor(
    private router: Router,
    private authService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.isLoggedIn();

    // Calls isLoggedIn once every minute
    this.updateInterval = interval(1000);
    this.sub = this.updateInterval.subscribe((val) => this.isLoggedIn());
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  /** Checks the user's local storage to see if the user has their credentials stored in it. */
  isLoggedIn(): void {
    this.loggedFlag = this.authService.isLoggedIn();

    if (this.loggedFlag) {
      this.username = this.authService.getUsername();
      this.loginTime = new Date(this.authService.getLoginTimestamp());
    } else {
      if (this.router.url !== '/login') {
        this.router.navigate(['/login']);
      }
    }
  }
}
