import {states} from '../../../api/models/user-address-model';
import {userStatus, userType, carriers} from '../../../api/models/user';
import { filterBy } from "../../user-management/models/user-search-filter";
import { fleetStatus, unitTypes } from '../../../api/models/fleet';

import * as UnitConversion from './unit-conversion';
import * as Validation from '../util/validators';

/** Parameters that defines a form-input attribute. Add new components to userParameterList to create new
 *  form inputs. */
export interface UserParameters {
  param: string;									// The name of this user parameter, which is tied to an attribute to an interface model used in this service
	label: string;									// A human-readable title of this parameter object
	hint?: string;									// Used to define a hint associated with this user parameter object (optional)
	validator?: any;								// Aux. function used to validate the expression (optional)
	items?: any;							    	// Defines the list of items accessible to this component if it happens to be tied to a dropdown field
	autocomplete?: string;          // Autocomplete metadata associated with this form
	extensions?: Array<string>;			// Defines a list of supported extensions if this form-input requires an uploaded file
}

/* Predefined list of parameters for any new form elements used in IOCats */
export const userParameterList: UserParameters[] = [
	{ param: 'id', label: 'ID', validator: Validation.validateNumber },
  { param: 'user_contact_id', label: 'Contact ID', validator: Validation.validateNumber },
	{ param: 'fleet_id', label: 'Fleet ID', validator: Validation.validateNumber },
	{ param: 'username', label: 'Username', hint: 'Username must be at least ' + Validation.minUsernameLength + ' characters long and begin with a letter', validator: Validation.validateUser, autocomplete: 'username'},
	{ param: 'password', label: 'Password', hint: 'Password must be at least '  + Validation.minPasswordLength + ' characters long', validator: Validation.validatePassword, autocomplete: 'new-password' },
	{ param: 'firstName', label: 'First name', validator: Validation.validateString, autocomplete: 'given-name' },
	{ param: 'lastName', label: 'Last name', validator: Validation.validateString, autocomplete: 'family-name' },
	{ param: 'email', label: 'Email', validator: Validation.validateEmail, autocomplete: 'email' },
	{ param: 'phone', label: 'Phone number', hint: 'Phone number must be in the format of (xxx) xxx-xxxx', validator: Validation.validatePhone, autocomplete: 'tel-national' },
	{ param: 'passwordConfirm', label: 'Confirm password', validator: Validation.validatePassword },
	{ param: 'zip', label: 'Zip / Postal Code', hint: 'Five digit postal code', validator: Validation.validateZip, autocomplete: 'postal-code' },
	{ param: 'state', label: 'State', items: states, autocomplete: 'address-level1'},
	{ param: 'address', label: 'Address', autocomplete: 'address-line1' },
	{ param: 'city', label: 'City', validator: Validation.validateString, autocomplete: 'address-level2' },
	{ param: 'userStatus', label: 'User status', hint: 'The user\'s account status', items: userStatus},
	{ param: 'user_type', label: 'User type', hint: 'The user\'s administrative privilege level', items: userType},
	{ param: 'user_contact', label: 'Contact phone number', hint: 'Contact number must be in the format of (xxx) xxx-xxxx', validator: Validation.validatePhone, autocomplete: 'tel-national'},
	{ param: 'filterParameter', label: 'Search parameter' },
	{ param: 'filterBy', label: 'Search By', items: filterBy, hint: 'Specifies the search scope' },
	{ param: 'modified_time', label: 'Modified time', validator: Validation.validateString},
	{ param: 'create_time', label: 'Create time', validator: Validation.validateString },
	{ param: 'action', label: 'Actions'},
	{ param: 'name', label: 'Name', validator: Validation.validateString},
	{ param: 'user_group_type', label: 'User group type', validator: Validation.validateString, items: userType},
	{ param: 'user_group_name', label: 'User group name', validator: Validation.validateString },
	{ param: 'unit_conversion', label: 'Unit conversion', items: UnitConversion.unitConversions },
	{ param: 'ioc_table_filter', label: 'Search Fleet'},
	{ param: 'fleet_contact', label: 'Fleet contact', hint: 'Fleet contact number must be in the format of (xxx) xxx-xxxx', validator: Validation.validatePhone },
	{ param: 'vehicle_type', label: 'Vehicle type', validator: Validation.validateString },
	{ param: 'fleet_logo', label: 'Fleet logo', validator: Validation.validateFile, extensions: Validation.imgFileTypes},
	{ param: 'active_status', label: 'Fleet status', items: fleetStatus },
	{ param: 'desc', label: 'Description', validator: Validation.validateString },
	{ param: 'prj_file', label: 'Project file', validator: Validation.validateFile, extensions: Validation.projectFileTypes },
	{ param: 'time_offset', label: 'Time offset', validator: Validation.validateString },
	{ param: 'default_unit_type', label: 'Default unit type', hint: 'Defines the default unit system used for this specific fleet', validator: Validation.validateString, items: unitTypes},
	{ param: 'fleet_functional_code', label: 'Fleet functional code', validator: Validation.validateString},
	{ param: 'time_zone', label: 'Time zone', validator: Validation.validateString },
	{ param: 'prj_desc', label: 'Project file description', hint: 'Description for the uploaded project file', validator: Validation.validateString },
	{ param: 'prj_version', label: 'Project file version', hint: 'The version number for this project file', validator: Validation.validateString },
	{ param: 'fleet_type', label: 'Fleet Type', validator: Validation.validateString },
	{ param: 'carrier', label: 'Phone Carrier', validator: Validation.validateString, items: carriers, hint: 'Your mobile phone carrier'},
	{ param: 'vehicle_number', label: 'Vehicle number', validator: Validation.validateString},
	{ param: 'serial_number', label: 'Serial number'},
	{ param: 'ethernet_mac', label: 'Ethernet mac'},
	{ param: 'WiFi_MAC', label: 'WiFi MAC'},
	{ param: 'canID', label: 'CAN ID'},
	{ param: 'can_id', label: 'CAN ID'},
	{ param: 'spn', label: 'SPN'},
	{ param: 'pgn', label: 'PGN'},
	{ param: 'startBit', label: 'Start Bit'},
	{ param: 'start_bit', label: 'Start Bit'},
	{ param: 'length', label: 'Length'},
	{ param: 'resolution', label: 'Resolution'},
	{ param: 'offset', label: 'offset'},
	{ param: 'resolution', label: 'Resolution'},
	{ param: 'unit', label: 'Unit'},
	{ param: 'VDL', label: 'VDL'},
	{ param: 'version', label: 'version'},
	{ param: 'MACAddress', label: 'MACAddress'},
	{ param: 'projectFile', label: 'Project File'},
	{ param: 'revision', label: 'Revision'},
	{ param: 'index', label: 'Index'},
	{ param: 'dinex_output', label: 'DINEX Output'},
	{ param: 'data_src_id', label: 'Data Src Id'},
	{ param: 'remark', label: 'Remark'},
	{ param: 'can_bus_desc', label: 'CAN Bus Description'},
	{ param: 'channel_name', label: 'Channel Name'},
	{ param: 'alert_type', label: 'Alert Type'},
	{ param: 'output', label: 'Output'},
]

/** Returns the UserParameter object given a specified parameter */
export function getUserParameter (param: string): UserParameters {
	return userParameterList.find (currentItem => {
		return param === currentItem.param;
	});
}

/** Returns the description of the specified parameter */
export function getHint (param: string): string {
	const paramObj: UserParameters = getUserParameter (param);
	return (paramObj && paramObj.hint) ? paramObj.hint : '';
}

/** Returns supported file extensions associated with this parameter */
export function getExtensions (param: string): Array<string> {
		const paramObj: UserParameters = getUserParameter (param);
		return (paramObj && paramObj.extensions) ? paramObj.extensions : null;
}

/** Generates the hint string for any parameters needing file input validation */
export function generateFileHint (param: string) {
	const extensions: Array<string> = getExtensions (param);
	return 'Max file size: ' + Validation.maxFilesize  + ' MB. Files must be in the format of ' + extensions.toString().replace (new RegExp (',', 'g'), ', ');
}
