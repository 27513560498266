export class GatewayInfoDto {
  gatewayInfoId: number;
  companyId: number;
  fleetId: number;
  vehicleId: number;
  deviceNumber: string;
  scheduleVersion: number | null;
  applicationVersion: number | null;
  statusTypeId: number;
  gatewayDescription: string | null;
  createdDate: Date;
  modifiedDate: Date;
  vin: string;
}
