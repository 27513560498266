import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, CanActivate, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../api/services/authentication.service';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate, OnDestroy {
  // Flag used to determine if this user is logged in
  private loggedFlag: boolean;
  // Subscription used to detect any issues with the client's auth token
  private tokenGuard: Subscription;

  constructor(
    public router: Router,
    private readonly route: ActivatedRoute,
    private authService: AuthenticationService
  ) {
    // Starts listening to any authentication issues
    this.tokenGuard = this.authService
      .getAuthListener()
      .subscribe((response) => {
        // Force logs out of the system if an issue arises with the client's token
        if (response.error) {
          console.log('[Token Guard]: ' + response.message);
          this.forceLogOut();
        }
      });
  }

  /** Disposes the token guard listener once this service is destroyed */
  ngOnDestroy() {
    if (this.tokenGuard) {
      this.tokenGuard.unsubscribe();
    }
  }

  /** Redirects the user to the login page if not authenticated */
  public canActivate() {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenFromUrl = urlParams.get('token');
    if (tokenFromUrl) {
      this.authService.saveUserCredentials(tokenFromUrl);
      this.router.navigate([], {
        queryParams: {
          token: null
        },
        queryParamsHandling: 'merge'
      });
      return true;
    }

    if (!this.isLoggedIn()) {
      this.authService.throwAuthError(
        'You need to be authenticated to access this resource'
      );
      this.forceLogOut();
    }
    return this.loggedFlag;
  }

  /** Checks the user's web-token to see if the user has their credentials stored in it */
  public isLoggedIn() {
    this.loggedFlag = this.authService.isLoggedIn();
    return this.loggedFlag;
  }

  /** Checks if the document of id target has a specified class */
  private hasClass(target: any, elementClassName: string) {
    return new RegExp('(\\s|^)' + elementClassName + '(\\s|$)').test(
      target.className
    );
  }

  /** Force log-outs the client */
  private forceLogOut() {
    // Force hides the sidebar if it is open
    if (!this.hasClass(document.querySelector('body'), 'sidebar-hidden')) {
      document.querySelector('body').classList.toggle('sidebar-hidden');
    }
    // Force hides the sidebar if it is open (in mobile)
    if (this.hasClass(document.querySelector('body'), 'sidebar-mobile-show')) {
      document.querySelector('body').classList.toggle('sidebar-mobile-show');
    }

    window.location.href = `${
      environment.ssoUrl
    }/auth/login?returnUrl=${encodeURIComponent(window.location.href)}`;
    // this.router.navigate(['logout']);
  }
}
