import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router'; // @author Eric Su
// Import Containers
import { FullLayoutComponent } from './containers';
// Auth. guard
// @author Eric Su
import { AuthGuardService as AuthGuard } from './services/auth-guard.service';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'fleet',
    pathMatch: 'full'
  },
  {
    path: '',
    component: FullLayoutComponent,
    data: {
      title: ''
    },
    children: [
      {
        path: 'fleet',
        loadChildren: './views/fleet/fleet.module#FleetModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'alert',
        loadChildren: './views/alert/alert.module#AlertModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'schedule',
        loadChildren: './views/schedule/schedule.module#ScheduleModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'scheduleUpload',
        loadChildren:
          './views/add-schedule-file/add-schedule-file.module#AddScheduleFileModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'application',
        loadChildren:
          './views/application-status/application-status.module#ApplicationStatusModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'applicationUpload',
        loadChildren:
          './views/add-application-file/add-application-file.module#AddApplicationFileModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'setting',
        loadChildren:
          './views/setting/setting.module#SettingModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'user',
        loadChildren: './views/profile/profile.module#ProfileModule',
        runGuardsAndResolvers: 'always',
        canActivate: [AuthGuard]
      },
      {
        path: 'login',
        loadChildren: './views/login/login.module#LoginModule'
      },
      {
        path: 'logout',
        loadChildren: './views/logout/logout.module#LogoutModule'
      },
      {
        path: 'forget',
        loadChildren:
          './views/forget-password/forget-password.module#ForgetPasswordModule'
      },
      {
        path: 'reset/:email/:token',
        loadChildren:
          './views/reset-password/reset-password.module#ResetPasswordModule'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
