import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { NgSpinKitModule } from 'ng-spin-kit';
import { FormElementsModule } from '../form-elements/form-elements.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SettingRoutingModule } from './setting-routing.module';
import { SettingComponent } from './setting.component';
import { GatewayComponent } from './gateway/gateway.component';
import { TabsModule } from 'ngx-bootstrap/tabs';


@NgModule({
  declarations: [SettingComponent, GatewayComponent],
  imports: [
    CommonModule,
    SettingRoutingModule,
    NgSpinKitModule,
    FormElementsModule,
    NgxDatatableModule,
    ReactiveFormsModule,
    NgbModule,
    FormsModule,
    TabsModule
  ]
})
export class SettingModule { }
